import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import {
  selectCustomerStatus,
  selectCustomerToken,
} from '../Profile/selectors';
import StripeInput from '../Account/StripeInput';

const SubscriptionStatus = () => {
  const location = useLocation();
  const customerStatus = useSelector(selectCustomerStatus);
  const customerToken = useSelector(selectCustomerToken);
  if (
    ['active', 'trialing'].includes(customerStatus) ||
    location.pathname.includes('account')
  ) {
    return null;
  }
  return (
    <div className='grid-x grid-padding-x' style={{ display: 'none' }}>
      <div className='small-12 cell float-center ds-container'>
        <div className='callout alert' style={{ color: 'black' }}>
          {['lead'].includes(customerStatus) && (
            <>
              <h4>Finish Signing Up</h4>
              <p>
                It looks like you did not complete the sign up{' '}
                <NavLink to={`/account/${customerToken}`}>
                  <strong>Click here to complete it.</strong>
                </NavLink>
              </p>
            </>
          )}
          {['past_due', 'unpaid', 'canceled'].includes(customerStatus) && (
            <>
              <h4>Update your payment method</h4>
              <p>
                There is a problem with your current payment method. Update
                billing information to keep your subscription active.{' '}
                <StripeInput />
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionStatus;
